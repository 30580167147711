import { useTranslation } from "next-i18next";
import { ArrowProps } from "../arrowControls/arrowControls";
import { forwardRef } from "react";

export interface LeftArrowProps extends ArrowProps {}

function LeftArrow(props: LeftArrowProps, ref: React.Ref<HTMLButtonElement>) {
  const { t: tPublic } = useTranslation("public");
  return (
    <button
      ref={ref}
      type="button"
      className={`left-arrow-button arrow left-arrow`}
      disabled={props.disabled}
      onClick={props.onClick}
      aria-label={tPublic("back")}
    ></button>
  );
}
export default forwardRef<HTMLButtonElement, LeftArrowProps>(LeftArrow);