import PbAutoComplete from "@/components/input/pbAutoComplete/pbAutoComplete";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { getPageTypeNameLocalized } from "@/services/cmsPageTypeService/cmsPageTypeService";
import { updateAttributeAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import { useAppSelector } from "@/store/store";
import { PbPageType } from "@/types/strapi";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";

const CmsSliderContentElementDropdown = (props: { position: number }) => {
  const tCms = useCmsTranslation();
  const dispatch = useDispatch();
  const pageTypes = useAppSelector((state) => state.cmsGeneral.pageTypes);

  return (
    <>
      <div className="cms-slider-select-page-type">
        <PbAutoComplete
          withCmsStyle
          label={tCms("slider-selectPageType")}
          options={
            pageTypes.length > 0
              ? pageTypes.map((pageType: PbPageType) => ({
                  label:
                    getPageTypeNameLocalized(
                      pageType,
                      process.env.NEXT_PUBLIC_CMS_USER_LANGUAGE!
                    ) ?? "",
                  id: pageType.id,
                }))
              : []
          }
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                style: {
                  color: "#fff",
                },
              }}
              placeholder={tCms("slider-selectPageType")}
              margin="none"
            />
          )}
          onChange={(e, value) => {
            if (value) {
              // Note: Currently only a single pageTypeId is wanted. Might change in the future.
              dispatch(
                updateAttributeAction({
                  attributePath: `draftPage.content[${props.position}].cfgSelectPageTypeIds`,
                  value: [parseInt(value.id, 10)],
                })
              );
            } else {
              dispatch(
                updateAttributeAction({
                  attributePath: `draftPage.content[${props.position}].cfgSelectPageTypeIds`,
                  value: [],
                })
              );
            }
            dispatch(
              updateAttributeAction({
                attributePath: `draftPage.content[${props.position}].cfgSelectOptionalCategoryIds`,
                value: [],
              })
            );
          }}
          getOptionLabel={(option) => `${option.label}`}
          id="-select-slider"
        />
      </div>
      <style jsx global>
        {`
          :global(.cms-slider-select-page-type) {
            margin-left: auto;
            margin-right: auto;
            width: fit-content;

            .MuiAutocomplete-endAdornment svg {
              fill: var(--pb-cms-color);
            }
            .cms-input-label {
              color: var(--pb-cms-color);
            }
            #cms-autocomplete-id-select-slider::placeholder,
            .cms-button-input::placeholder {
              color: var(--pb-cms-color);
              opacity: 0.85 !important;
            }
            .cms-info-text {
              color: var(--pb-cms-color);
            }
          }
        `}
      </style>
    </>
  );
};

export default CmsSliderContentElementDropdown;
