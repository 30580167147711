import {
  A11y,
  Navigation,
  Pagination,
  Scrollbar,
  Virtual,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperComponentStyleGlobal from "./swiperComponentStyleGlobal";

import CmsImage from "@/components/cms/cmsImage/cmsImage";
import OptimizeStrapiImage from "@/components/cms/cmsImage/optimizeStrapiImage";
import PbContentButton from "@/components/content/pbContentButton/pbContentButton";
import PbContentHeading from "@/components/content/pbContentHeading/pbContentHeading";
import PbContentRichText from "@/components/content/pbContentRichText/pbContentRichText";
import ArrowControls from "@/components/util/arrowControls/arrowControls";
import useCesStrButton from "@/hooks/useCesStrButton";
import useCesStrHeadline from "@/hooks/useCesStrHeadline";
import useCesStrRichText from "@/hooks/useCesStrRichText";
import {
  getNestedCeSettingsData,
  valueFromStoreSetting,
} from "@/services/ceSettings/ceSettingsService";
import {
  getPbContentScopedSelector,
  getRandomSlug,
  lowerBreakpointDesktop,
  lowerBreakpointMobile,
  lowerBreakpointTablet,
  lowerBreakpointWqhd
} from "@/utils/util";
import { useRouter } from "next/router";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { SwiperComponentProps } from "@/types/content-elements";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/virtual";

export default function SwiperComponent(props: SwiperComponentProps) {
  const [slides, setSlides] = useState<Array<any>>(
    props.swiperContentData ? props.swiperContentData : []
  );
  const [noMoreSlides, setNoMoreSlides] = useState(false);
  const arrowsId = props.ceSettings
    ? valueFromStoreSetting(props.ceSettings?.arrows)
    : undefined;

  const isLoading = useRef(false);

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const router = useRouter();

  const { t: tPublic } = useTranslation("public");

  const getMoreSlides = async () => {
    if (isLoading.current || noMoreSlides) {
      return;
    }
    isLoading.current = true;
    const getMoreSlidesParams = {
      ...props.getMoreSlidesFunctionParams,
      chunkOffset: slides.length,
    };
    const pageDataChunkedResult = await props.getMoreSlidesFunction(
      getMoreSlidesParams
    );

    if (
      pageDataChunkedResult.success &&
      pageDataChunkedResult.data.length > 0
    ) {
      setSlides((prevSlides) => [...prevSlides, ...pageDataChunkedResult.data]);
    } else {
      setNoMoreSlides(true);
    }
    isLoading.current = false;
  };

  const teaserHeadlineSettings = useCesStrHeadline({
    typography: props.content.cfgStrInnerTeaserTypography,
    font: props.content.cfgStrInnerTeaserFont,
    fontColor: props.content.cfgStrInnerTeaserFontColor,
    ceSettingHeadline: props.ceSettings?.teaserheading,
    cfgHeadlineType: props.content.cfgHeadlineTypeTeaser,
    ceSettings: getNestedCeSettingsData(props.ceSettings?.teaser),
  });

  const titleHeadlineSettings = useCesStrHeadline({
    typography: props.content.cfgStrInnerTitleTypography,
    font: props.content.cfgStrInnerTitleFont,
    fontColor: props.content.cfgStrInnerTitleFontColor,
    ceSettingHeadline: props.ceSettings?.titleheading,
    cfgHeadlineType: props.content.cfgHeadlineTypeTitle,
    ceSettings: getNestedCeSettingsData(props.ceSettings?.title),
  });

  const { buttonClassName } = useCesStrButton(
    props.content.cfgStrInnerButtonButton,
    props.ceSettings?.button
  );

  const { richTextClassName, richtextEditorSettings } = useCesStrRichText(
    props.ceSettings?.previewText
  );

  const renderSwiperContentData = (content: any) => {
    switch (props.layout) {
      case "image":
        return null;
      case "contact":
        return null;
      default:
        return (
          <div className="default-slide-layout">
            <div className="slide-image">
              {content.thumbnail && content.thumbnail.img ? (
                <OptimizeStrapiImage
                  image={content.thumbnail.img}
                  isMobile={true}
                  widthLimitSettings={400}
                >
                  <CmsImage
                    width={"100%"}
                    height={"300px"}
                    image={content.thumbnail.img}
                    objectFit="cover"
                  />
                </OptimizeStrapiImage>
              ) : null}
            </div>
            <div className="slide-content">
              {content.thumbnail && content.thumbnail.teaser ? (
                <PbContentHeading
                  disableEditView={true}
                  content={content.thumbnail.teaser}
                  settings={teaserHeadlineSettings}
                  scopedSelector={getPbContentScopedSelector(
                    props.scopedClassName,
                    "teaser",
                    content.id
                  )}
                />
              ) : null}
              {content.thumbnail && content.thumbnail.title ? (
                <PbContentHeading
                  disableEditView={true}
                  content={content.thumbnail.title}
                  settings={titleHeadlineSettings}
                  scopedSelector={getPbContentScopedSelector(
                    props.scopedClassName,
                    "title",
                    content.id
                  )}
                />
              ) : null}
              {content.thumbnail && content.thumbnail.previewText ? (
                <PbContentRichText
                  disableEditView={true}
                  className={richTextClassName}
                  content={content.thumbnail.previewText}
                  ceSettings={richtextEditorSettings}
                />
              ) : null}
            </div>
            {props.ceSettings?.showButton ? (
              <div className="slide-button">
                {/* TBD: Or make the slide-button always visible? */}
                <PbContentButton
                  disableEditView={true}
                  text={
                    props.content.cfgSlideButtonText ||
                    tPublic("slider-slideButtonTextFallback")
                  }
                  url={content.url}
                  className={buttonClassName}
                />
              </div>
            ) : null}
          </div>
        );
    }
  };

  return (
    <>
      <Swiper
        modules={[Virtual, Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={props.ceSettings?.spaceBetween?.mobile ?? 10}
        slidesPerView={props.ceSettings?.slidesPerView?.mobile ?? 1}
        grabCursor={true}
        virtual={true}
        // 1.3.
        // We might have to resonsider breakpointsBase with 1.3.
        // Note: We will still "loose" the spaceX-space when comparing container-query: layout
        // and container-query swiper-container.
        // defaults to window:
        // breakpointsBase={"container"}
        // Responsive breakpoints:
        breakpoints={{
          [lowerBreakpointMobile()]: {
            slidesPerView: props.ceSettings?.slidesPerView?.mobile ?? 1,
            spaceBetween: props.ceSettings?.spaceBetween?.mobile ?? 10,
          },
          [lowerBreakpointTablet()]: {
            slidesPerView: props.ceSettings?.slidesPerView?.tablet ?? 2,
            spaceBetween: props.ceSettings?.spaceBetween?.tablet ?? 20,
          },
          [lowerBreakpointDesktop()]: {
            slidesPerView: props.ceSettings?.slidesPerView?.desktop ?? 3,
            spaceBetween: props.ceSettings?.spaceBetween?.desktop ?? 30,
          },
          [lowerBreakpointWqhd()]: {
            slidesPerView: props.ceSettings?.slidesPerView?.wqhd ?? 4,
            spaceBetween: props.ceSettings?.spaceBetween?.wqhd ?? 40,
          },
        }}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        pagination={{ clickable: true }}
        onReachEnd={() => {
          getMoreSlides();
        }}
        // Note: This allows the configuration/overwrite of swiper parameter-props via ceSettings.swiperjsProps-Attribute.
        // https://swiperjs.com/swiper-api#parameters
        // (components_pb_cssldr, make sure to trigger css-generation afterwards)
        // This is not recommended and not tested on any layout!
        {...(props.ceSettings?.swiperjsProps
          ? props.ceSettings.swiperjsProps
          : {})}
      >
        <ArrowControls
          showLeftArrow={true}
          showRightArrow={true}
          arrowsId={arrowsId}
          prevRef={prevRef}
          nextRef={nextRef}
          classNameLeft={`${slides.length === 0 ? "d-none" : ""}`}
          classNameRight={`${slides.length === 0 ? "d-none" : ""}`}
        />
        {slides.map((content: any, index: number) => {
          return (
            <SwiperSlide
              onClick={
                !props.ceSettings?.showButton
                  ? () => router.push(content.url)
                  : undefined
              }
              key={`${content?.id}-${index}-${
                props.content.cfgSlideButtonText
                  ? props.content.cfgSlideButtonText
                  : ""
              }`}
              virtualIndex={parseInt(
                `${content?.id ? content.id : getRandomSlug()}${index}`
              )}
            >
              {renderSwiperContentData(content)}
            </SwiperSlide>
          );
        })}
      </Swiper>
      <SwiperComponentStyleGlobal {...props} />
    </>
  );
}
