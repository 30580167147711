import { STORE_ARROW_CLASS_PREFIX } from "@/utils/constants";
import clsx from "clsx";
import LeftArrow from "../arrows/leftArrow";
import RightArrow from "../arrows/rightArrow";
import ArrowControlsStyle from "./arrowControlsStyle";

export interface ArrowControlsProps {
  hideArrows?: boolean;
  onNextClick?: Function;
  onPrevClick?: Function;
  fade?: boolean;
  showRightArrow: boolean;
  showLeftArrow: boolean;
  classNameLeft?: string;
  classNameRight?: string;
  /**
   * the strapi arrow store id. If not given a fallback arrow is shown.
   */
  arrowsId?: number;
  prevRef?: React.Ref<HTMLButtonElement>;
  nextRef?: React.Ref<HTMLButtonElement>;
}

export interface ArrowProps {
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const ArrowControls = (props: ArrowControlsProps) => {
  const {
    hideArrows,
    showRightArrow,
    showLeftArrow,
    onNextClick,
    onPrevClick,
    fade,
    classNameLeft,
    classNameRight,
    arrowsId,
    prevRef,
    nextRef,
  } = props;

  if (hideArrows) {
    return null;
  }

  return (
    <>
      <div
        className={clsx(
          !showLeftArrow && "d-none",
          "arrow-container left-arrow-container",
          "on-top-of-content",
          fade && "fade",
          classNameLeft,
          `${STORE_ARROW_CLASS_PREFIX}${arrowsId}`
        )}
      >
        <LeftArrow
          ref={prevRef}
          onClick={() => {
            if (onPrevClick) {
              onPrevClick();
            }
          }}
        />
      </div>
      <div
        className={clsx(
          !showRightArrow && "d-none",
          "arrow-container right-arrow-container",
          "on-top-of-content",
          fade && "fade",
          classNameRight,
          `${STORE_ARROW_CLASS_PREFIX}${arrowsId}`
        )}
      >
        <RightArrow
          ref={nextRef}
          onClick={() => {
            if (onNextClick) {
              onNextClick();
            }
          }}
        />
      </div>
      <ArrowControlsStyle />
    </>
  );
};

export default ArrowControls;
