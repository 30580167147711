import { useTranslation } from "next-i18next";
import { forwardRef } from "react";
import { ArrowProps } from "../arrowControls/arrowControls";

export interface RightArrowProps extends ArrowProps {}

function RightArrow(props: RightArrowProps, ref: React.Ref<HTMLButtonElement>) {
  const { t: tPublic } = useTranslation("public");
  return (
    <button
      ref={ref}
      type="button"
      className={`right-arrow-button arrow right-arrow`}
      disabled={props.disabled}
      onClick={props.onClick}
      aria-label={tPublic("next")}
    ></button>
  );
}

export default forwardRef<HTMLButtonElement, RightArrowProps>(RightArrow);