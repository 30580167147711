SwiperComponentStyleGlobal;
import { SLIDER_CE_NAME } from "./../sliderContentElement";

/**
 * Note: This is NOT the place for the styling of the Slider-Content-Element!
 * Keep the styling inside general, so that it could be reused elsewhere.
 * @param props
 * @returns
 */
export default function SwiperComponentStyleGlobal(props: any) {
  return (
    <style jsx global>
      {`
        :global(.${SLIDER_CE_NAME}) {
          .swiper {
            --swiper-pagination-bottom: -7px;

            height: 100%;
            width: 100%;
            .arrow-container.on-top-of-content {
              top: 0;
            }

            .arrow-container {
              .swiper-button-disabled {
                display: none;
              }
            }

            .swiper-slide {
              overflow: hidden;
              /* Prevents highlighting text when accidentally marking text instead of swiping */
              user-select: none;

              .default-slide-layout {
                // Removed because of button always on bottom:
                // display: flex;
                // flex-direction: column;
                // justify-content: space-between;
                height: 100%;

                .slide-content {
                  padding: 8px;

                  /* Note: This is unwanted, however it just looks to bad without. */
                  .pb-richtext {
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }

                .slide-button {
                  width: 100%;
                  position: absolute;
                  bottom: 0;
                  padding: 8px;
                }
              }
            }
          }
          .swiper-wrapper {
            align-items: center;
          }
        }
      `}
    </style>
  );
}
